/* The emerging W3C standard
   that is currently Firefox-only */

* {
    scrollbar-width: thin;
    scrollbar-color: lightgray white;
}

/* Works on Chrome/Edge/Safari */

*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: white;
}

*::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 20px;
    border: 3px solid white;
}